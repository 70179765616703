import request from "@/utils/request";

/**
 * 发送验证码
 * @param {string} data.phone 手机号
 * @returns
 */
export function sendCode(data) {
  return request({
    url: "/user/sendCode",
    method: "post",
    data
  });
}

/**
 * 手机登录
 * @param {string} data.phone 手机号
 * @param {string} data.code 手机号
 * @returns
 */
export function loginByPhone(data) {
  return request({
    url: "/user/loginByPhone",
    method: "post",
    data
  });
}

/**
 * 获取用户信息
 * @returns
 */
export function getInfo() {
  return request({
    url: "/user/info",
    method: "get"
  });
}
