<template>
  <div class="navbar">
    <div class="navbar-inner">
      <div class="logo">
        <img alt="logo" src="@/assets/logo.png" />
      </div>
      <ul class="nav">
        <li class="nav-item">
          <router-link to="/home">首页</router-link>
        </li>
      </ul>
      <div v-if="user.id" class="user-wrap">
        <el-popover
          placement="bottom"
          width="300"
          trigger="hover"
          popper-class="user-popover"
        >
          <div class="user-card">
            <div class="user-card-top">
              <img src="@/assets/avatar.png" alt="" />
              <div class="num">ID：{{ user.id }}</div>
            </div>
            <div class="user-card-body">
              <div @click="$router.push('/my/info')" class="item">
                <i class="icon el-icon-user"></i>
                <span>用户资料</span>
              </div>
              <div @click="$router.push('/my/record')" class="item">
                <span class="icon iconfont icon-coupon"></span>
                <span>近7天星钻记录</span>
              </div>
            </div>
            <div class="divider-wrap">
              <el-divider></el-divider>
            </div>
            <div class="user-card-footer">
              <el-button type="text" @click="logout">退出</el-button>
            </div>
          </div>
          <div class="user-info" slot="reference">
            <img src="@/assets/avatar.png" alt="" />
            <span>{{ user.nickName }}</span>
          </div>
        </el-popover>
      </div>
      <div v-else class="ctrl">
        <i class="el-icon-user-solid"></i>
        <span class="login" @click="dialogVisible = true">登录/注册</span>
      </div>

      <el-dialog
        :visible.sync="dialogVisible"
        width="663px"
        class="login-dialog"
      >
        <div>
          <img class="img" src="@/assets/login.png" alt="" />
          <div class="form-wrap">
            <div class="title">登录/注册</div>
            <el-form ref="form" class="form" :model="form">
              <el-form-item>
                <el-input v-model="form.phone" placeholder="手机号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-row :gutter="10">
                  <el-col :span="14">
                    <el-input
                      v-model="form.code"
                      placeholder="验证码"
                    ></el-input
                  ></el-col>
                  <el-col :span="10">
                    <el-button class="code" :disabled="count != 0" @click="sendCode">{{ count > 0 ? `${count} 秒后重新发送` : '获取验证码' }} </el-button>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item>
                <el-button class="submit" type="primary" @click="loginByPhone"
                  >登录/注册</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { sendCode } from "@/api/user.js";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        phone: "",
        code: ""
      },
      count: 0
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.$bus.$on("openLogin", this.openLogin);
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    loginByPhone() {
      this.$store.dispatch("loginByPhone", this.form).then(() => {
        this.$message({
          message: "登录成功",
          type: "success"
        });
        this.$store.dispatch("getInfo");
        this.dialogVisible = false;
      });
    },
    sendCode() {
      sendCode({ phone: this.form.phone }).then(res => {
        this.$message({
          message: "发送成功",
          type: "success"
        });
        console.log(res.data);
        this.count = 60;
        const interval = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            clearInterval(interval);
            this.count = 0;
          }
        }, 1000);
      });
    },
    openLogin() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
.navbar {
  background: #ffffff;
  .navbar-inner {
    padding: 0 10px;
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .logo {
      flex: 1;
      margin-top: 35px;
    }
    .nav {
      margin-top: 35px;
      margin-bottom: 0;
      display: inline-block;
      overflow: hidden;
      margin-right: 60px;
      .nav-item {
        & + .nav-item {
          margin-left: 50px;
        }
        margin: 0;
        float: left;
        list-style: none;
        a {
          text-decoration: none;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
          color: #333333;
          &.router-link-active {
            color: $--color-primary;
            &::after {
              content: "";
              display: block;

              height: 3px;
              background: #16a085;
            }
          }
        }
      }
    }
    .ctrl {
      display: inline-block;
      margin-top: 35px;
      font-size: 14px;
      line-height: 20px;
      color: #666666;

      .el-icon-user-solid {
        margin-right: 10px;
      }

      .login {
        cursor: pointer;
      }
    }
  }
}
/deep/.el-dialog {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  .img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .form-wrap {
    width: 420px;
    position: absolute;
    left: 243px;
    top: 50px;
    text-align: center;
    .title {
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: bold;
      line-height: 37px;
      color: #16a085;
    }
    .form {
      width: 330px;
      margin: 0 auto;
      .code {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
      .submit {
        margin-top: 10px;
        width: 260px;
      }
    }
  }
}
/deep/.el-popover {
  padding: 0 !important;
}
.user-card {
  .user-card-top {
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    height: 60px;
    background-image: linear-gradient(86deg, #16a085 0%, #69d9c3 100%);
    display: flex;
    align-items: center;
    img {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 30px;
    }
    .num {
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }
  .user-card-body {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    .item {
      cursor: pointer;
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        height: 35px;
      }
      .el-icon-user {
        font-size: 28px;
      }
      .iconfont {
        font-size: 24px;
      }
      span {
        font-size: 12px;
        color: #333333;
      }
    }
  }
  .divider-wrap {
    padding: 0 10px;
    .el-divider {
      margin-bottom: 0;
    }
  }
  .user-card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  img {
    width: 42px;
    height: 42px;
    margin-bottom: 5px;
  }
  span {
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
}
</style>

<style lang="scss">
.user-popover {
  padding: 0 !important;
  .popper__arrow::after {
    border-bottom-color: #69d9c3 !important;
  }
}
</style>
