import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Index.vue";
// import { getToken } from "@/utils/auth";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home/lsMain"
  },
  {
    path: "/home",
    name: "Home",
    redirect: "/home/lsMain",
    component: Home,
    children: [
      {
        path: "lsMain",
        name: "lsMain",
        component: () =>
          import(/* webpackChunkName: "Main" */ "../views/home/Main.vue")
      },
      {
        path: "sfMain",
        name: "sfMain",
        component: () =>
          import(/* webpackChunkName: "Main" */ "../views/home/Main.vue")
      },
      {
        path: "showNice",
        name: "showNice",
        component: () =>
          import(/* webpackChunkName: "Main" */ "../views/home/Main.vue")
      },
      {
        path: "print",
        component: () =>
          import(/* webpackChunkName: "Print" */ "../views/home/Print.vue")
      }
    ]
  },
  {
    path: "/xinshoucun",
    name: "Xinshoucun",
    redirect: "/xinshoucun/renshiyapan",
    component: () =>
      import(
        /* webpackChunkName: "Xinshoucun" */ "../views/xinshoucun/Index.vue"
      ),
    children: [
      {
        path: "renshiyapan",
        component: () =>
          import(
            /* webpackChunkName: "Renshiyapan" */ "../views/xinshoucun/Renshiyapan.vue"
          )
      },
      {
        path: "yapanbianhua",
        component: () =>
          import(
            /* webpackChunkName: "Yapanbianhua" */ "../views/xinshoucun/Yapanbianhua.vue"
          )
      },
      {
        path: "renshioupei",
        component: () =>
          import(
            /* webpackChunkName: "Renshioupei" */ "../views/xinshoucun/Renshioupei.vue"
          )
      },
      {
        path: "renshidaxiaoqiu",
        component: () =>
          import(
            /* webpackChunkName: "Renshidaxiaoqiu" */ "../views/xinshoucun/Renshidaxiaoqiu.vue"
          )
      }
    ]
  },
  {
    path: "/zhihuixing",
    name: "Zhihuixing",
    redirect: "/zhihuixing/yapan",
    component: () =>
      import(/* webpackChunkName: "LsMain" */ "../views/zhihuixing/Index.vue"),
    children: [
      {
        path: "yapan",
        component: () =>
          import(
            /* webpackChunkName: "Yapan" */ "../views/zhihuixing/Yapan.vue"
          )
      },
      {
        path: "oupei",
        component: () =>
          import(
            /* webpackChunkName: "Oupei" */ "../views/zhihuixing/Oupei.vue"
          )
      },
      {
        path: "daxiaoqiu",
        component: () =>
          import(
            /* webpackChunkName: "Daxiaoqiu" */ "../views/zhihuixing/Daxiaoqiu.vue"
          )
      }
    ]
  },
  {
    path: "/xingdaohang",
    name: "Xingdaohang",
    component: () =>
      import(/* webpackChunkName: "LsMain" */ "../views/xingdaohang/Index.vue")
  },
  {
    path: "/fenxi",
    component: () =>
      import(/* webpackChunkName: "Fenxi" */ "../views/fenxi/Index.vue"),
    children: [
      {
        path: "jibenmian",
        component: () =>
          import(
            /* webpackChunkName: "Jibenmian" */ "../views/fenxi/Jibenmian.vue"
          )
      },
      {
        path: "yaou",
        component: () =>
          import(/* webpackChunkName: "Yaou" */ "../views/fenxi/Yaou.vue")
      },
      {
        path: "yapan",
        component: () =>
          import(/* webpackChunkName: "Yapan" */ "../views/fenxi/Yapan.vue")
      },
      {
        path: "oupei",
        component: () =>
          import(/* webpackChunkName: "Oupei" */ "../views/fenxi/Oupei.vue")
      },
      {
        path: "daxiaoqiu",
        component: () =>
          import(
            /* webpackChunkName: "Daxiaoqiu" */ "../views/fenxi/Daxiaoqiu.vue"
          )
      }
    ]
  },
  {
    path: "/my",
    name: "My",
    redirect: "/my/info",
    component: () =>
      import(/* webpackChunkName: "My" */ "../views/my/Index.vue"),
    children: [
      {
        path: "info",
        component: () =>
          import(/* webpackChunkName: "Info" */ "../views/my/Info.vue")
      },
      {
        path: "record",
        component: () =>
          import(/* webpackChunkName: "Record" */ "../views/my/Record.vue")
      }
    ]
  },
  {
    path: "/display/step1",
    name: "step1",
    component: () =>
      import(/* webpackChunkName: "step1" */ "../views/display/Step1.vue")
  },
  {
    path: "/display/step2",
    name: "step2",
    component: () =>
      import(/* webpackChunkName: "step2" */ "../views/display/Step2.vue")
  },
  {
    path: "/display/step3",
    name: "step3",
    component: () =>
      import(/* webpackChunkName: "step3" */ "../views/display/Step3.vue")
  },
  {
    path: "/display-fenxi",
    component: () =>
      import(
        /* webpackChunkName: "Fenxi" */ "../views/display-fenxi/Index.vue"
      ),
    children: [
      {
        path: "jibenmian/:type",
        component: () =>
          import(
            /* webpackChunkName: "Jibenmian" */ "../views/display-fenxi/Jibenmian.vue"
          )
      },
      {
        path: "yaou/:type",
        component: () =>
          import(
            /* webpackChunkName: "Yaou" */ "../views/display-fenxi/Yaou.vue"
          )
      },
      {
        path: "yapan",
        component: () =>
          import(
            /* webpackChunkName: "Yapan" */ "../views/display-fenxi/Yapan.vue"
          )
      },
      {
        path: "oupei",
        component: () =>
          import(
            /* webpackChunkName: "Oupei" */ "../views/display-fenxi/Oupei.vue"
          )
      },
      {
        path: "daxiaoqiu",
        component: () =>
          import(
            /* webpackChunkName: "Daxiaoqiu" */ "../views/display-fenxi/Daxiaoqiu.vue"
          )
      }
    ]
  }
];

let router = new VueRouter({
  routes
});
// const whiteList = ["/home", "/xinshoucun", "/zhihuixing", "/xingdaohang"]; // no redirect whitelist

// router.beforeEach(async (to, from, next) => {
//   const hasToken = getToken();
//   if (hasToken) {
//     let userid = store.state.user.userid;
//     if (!userid) {
//       await store.dispatch("getInfo");
//     }
//     next();
//   } else {
//     /* has no token*/
//     if (
//       whiteList.some(item => {
//         return to.path.indexOf(item) >= 0;
//       })
//     ) {
//       // in the free login whitelist, go directly
//       next();
//     } else {
//       // other pages that do not have permission to access are redirected to the login page.
//       next(`/`);
//     }
//   }
// });

export default router;
