import Vue from "vue";
import Vuex from "vuex";
import { getToken, setToken, removeToken } from "@/utils/auth";

Vue.use(Vuex);
import { loginByPhone, getInfo } from "@/api/user.js";

export default new Vuex.Store({
  state: {
    token: getToken(),
    user: {},
    good: {},
    isShowPayDialog: false
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    REOMVE_TOKEN: state => {
      state.token = "";
      removeToken();
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    REMOVE_USER: state => {
      state.user = {};
    },
    SET_GOOD(state, good) {
      state.good = good;
    }
  },
  actions: {
    showPayDialog({ commit, state }, data) {
      commit("SET_GOOD", data);
      state.isShowPayDialog = true;
    },
    closePayDialog({ state }) {
      state.isShowPayDialog = false;
    },
    loginByPhone({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginByPhone(data)
          .then(res => {
            console.log(res);
            let token = res.data;
            commit("SET_TOKEN", token);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // get user info
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(res => {
            const { data } = res;

            if (!data) {
              reject("Verification failed, please Login again.");
              return;
            }

            commit("SET_USER", data);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // user logout
    logout({ commit }) {
      commit("REOMVE_TOKEN");
      commit("REMOVE_USER");
    }
  },
  modules: {}
});
