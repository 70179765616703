<template>
  <el-dialog
    :visible="visible"
    width="715px"
    class="dialog"
    :before-close="beforeClose"
  >
    <div>
      <div class="title">
        <div class="team left">
          <span class="score">[英甲18]</span><span class="name">弗利特</span>
        </div>
        <div class="line"></div>
        <div class="team right">
          <span class="name">桑德兰</span><span class="score">[英甲22]</span>
        </div>
      </div>
      <div class="count">现有星钻优惠券剩余：3张</div>
      <div class="use-count">当前使用：<span>1</span>张</div>
      <el-button type="primary">确定</el-button>
      <div class="tip">如果需阅读免费分析，请点击<span>免费专栏</span></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },
  methods: {
    beforeClose() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__body {
    padding: 15px 65px;
  }
}
.title {
  height: 80px;
  background: #f6f7f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 0 auto 40px;
  .team {
    flex: 1;
    &.left {
      text-align: right;
    }
    .score {
      font-size: 20px;
      color: #999999;
      position: relative;
      top: -2px;
      margin: 5px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
  }
  .line {
    width: 32px;
    height: 1px;
    background: #aeaeae;
    margin: 0 15px;
  }
}
.count {
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
}
.use-count {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
  span {
    color: #ff5124;
    font-size: 36px;
  }
}
.el-button {
  width: 260px;
  margin: 0 auto 25px;
  display: block;
}
.tip {
  margin-bottom: 35px;
  color: #666666;
  text-align: center;
  span {
    color: #16a085;
    cursor: pointer;
  }
}
</style>
