<template>
  <div class="home">
    <img class="banner" src="@/assets/banner.png" alt="" />
    <div class="body">


      <div class="main">
        <router-view />
      </div>
    </div>
    <coupon-dialog :visible.sync="visible1" />
    <pay-dialog :visible="isShowPayDialog" @close="closePayDialog" />
    <pay-success-dialog :visible.sync="visible3" />
  </div>
</template>

<script>
import CouponDialog from "@/components/CouponDialog.vue";
import PayDialog from "@/components/PayDialog.vue";
import PaySuccessDialog from "@/components/PaySuccessDialog.vue";
export default {
  components: { CouponDialog, PayDialog, PaySuccessDialog },
  data() {
    return {
      visible1: false,
      visible2: false,
      visible3: false
    };
  },
  mounted() {},
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
    isShowPayDialog() {
      return this.$store.state.isShowPayDialog;
    }
  },
  methods: {
    handleClick() {},
    closePayDialog() {
      this.$store.dispatch("closePayDialog");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/vars.scss";
.banner {
  display: block;
}
.body {
  display: flex;
  padding: 15px;
  background: #fff;
  .aside {
    margin-right: 20px;
    position: sticky;
    top: 0;
    min-height: 420px;
    height: calc(100vh - 80px - 160px - 15px * 2 - 78px);
    .menu-wrap {
      background: $--color-primary;
      margin-bottom: 15px;

      padding: 5px;
      .el-menu {
        width: 200px;
        border-right: none;
        .el-menu-item {
          .title {
            display: flex;
            .iconfont {
              margin-right: 10px;
            }
            .text {
              flex: 1;
            }
            .el-icon-arrow-right {
              line-height: inherit;
            }
          }
          &:not(:last-child) {
            &::after {
              display: block;
              content: "";
              width: 155px;
              height: 1px;
              background: #ebe8e8;
              opacity: 0.24;
              z-index: 100;
              position: relative;
              top: -2px;
              left: 5px;
            }
          }

          &.is-active {
            background-color: rgba(255, 255, 255, 0.8) !important;
          }
        }
      }
    }
    .weixin {
      width: 100%;
      position: absolute;
      bottom: 100px;
      height: 78px;
      background: #f6f7f8;
      display: flex;
      align-items: center;
      .icon-weixin {
        font-size: 32px;
        color: #c8c8c8;
        margin-left: 15px;
        margin-right: 15px;
        .content {
          font-size: 16px;
          line-height: 22px;
          color: #584f4f;
        }
      }
    }
  }

  .main {
    flex: 1;
  }
}
</style>
