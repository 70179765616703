import request from "@/utils/request";

export function getWXPayUrl(params) {
  return request({
    url: "/order/getWXPayUrl",
    method: "get",
    params
  });
}

export function verifyOrder(params) {
  return request({
    url: "/order/verifyOrder",
    method: "get",
    params
  });
}
