<template>
  <el-dialog
    :visible="visible"
    width="715px"
    class="dialog"
    :before-close="beforeClose"
  >
    <div>
      <div class="title">
        <div class="team left">
          <span class="score">[{{ good.zteam_paiming }}]</span
          ><span class="name">{{ good.zteam }}</span>
        </div>
        <div class="line"></div>
        <div class="team right">
          <span class="name">{{ good.kteam }}</span
          ><span class="score">[{{ good.kteam_paiming }}]</span>
        </div>
      </div>
      <div class="sub-title">
        需支付：<span>{{ good.money }}</span
        >星钻（{{ good.money }}元）
      </div>
      <div class="qrcode-wrap">
        <div class="qrcode-item weixin">
          <div class="qrcode" ref="qrCodeUrl"></div>
          <div class="text">
            <span class="iconfont icon-weixinzhifu"></span>微信支付
          </div>
        </div>

        <!-- <div class="qrcode-item zhifubao">
          <div class="qrcode"></div>
          <div class="text">
            <span class="iconfont icon-zhifubao"></span>支付宝支付
          </div>
        </div> -->
      </div>
      <div class="tip">如果需阅读免费分析，请点击<span>免费专栏</span></div>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
import { getWXPayUrl } from "@/api/order.js";
export default {
  props: {
    visible: Boolean
  },
  data() {
    return {
      codeUrl: "",
      qrcode: null
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.getWXPayUrl();
      }
    }
  },
  computed: {
    good() {
      return this.$store.state.good;
    }
  },
  methods: {
    getWXPayUrl() {
      getWXPayUrl({ id: this.good.id }).then(res => {
        this.codeUrl = res.data.code_url;
        console.log(res);
        if (this.qrcode) {
          this.qrcode.makeCode(this.codeUrl);
          return;
        }
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.codeUrl,
          width: 180,
          height: 180,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H
        });
      });
    },
    beforeClose() {
      this.$emit("close", false);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__body {
    padding: 15px 65px;
  }
}
.title {
  height: 80px;
  background: #f6f7f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 0 auto 35px;
  .team {
    flex: 1;
    &.left {
      text-align: right;
    }
    .score {
      font-size: 20px;
      color: #999999;
      position: relative;
      top: -2px;
      margin: 5px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
  }
  .line {
    width: 32px;
    height: 1px;
    background: #aeaeae;
    margin: 0 15px;
  }
}
.sub-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
  margin-bottom: 30px;
  span {
    font-size: 30px;
    color: #ff4d4d;
  }
}
.qrcode-wrap {
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
  margin-bottom: 40px;
  .qrcode-item {
    .qrcode {
      width: 180px;
      height: 180px;
      border: 2px solid;
      margin-bottom: 25px;
    }
    .text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont {
        font-size: 36px;
        margin-right: 5px;
        font-weight: normal;
        &.icon-weixinzhifu {
          color: #4ab415;
        }
        &.icon-zhifubao {
          color: #02aaee;
        }
      }
    }

    &.weixin {
      .qrcode {
        border-color: #4ab415;
      }
    }
    &.zhifubao {
      .qrcode {
        border-color: #02aaee;
      }
    }
  }
}
.el-button {
  width: 260px;
  margin: 0 auto 25px;
  display: block;
}
.tip {
  margin-bottom: 35px;
  color: #666666;
  text-align: center;
  span {
    color: #16a085;
    cursor: pointer;
  }
}
</style>
