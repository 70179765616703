<template>
  <div v-if="isDisplay" class="display">
    <router-view />
  </div>
  <div v-else id="app">
    <navbar />
    <div class="main">
      <router-view />
    </div>
    <footer-bar />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
export default {
  name: "Home",
  components: {
    Navbar,
    FooterBar: Footer
  },
  computed: {
    isDisplay() {
      return this.$route.path.indexOf("display") >= 0;
    }
  }
};
</script>
<style>
body {
  background: #f0f1f6;
  margin: 0;
}
</style>
<style lang="scss" scoped>
.main {
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 80px - 78px);
}
</style>
