<template>
  <el-dialog
    :visible="visible"
    width="715px"
    class="dialog"
    :before-close="beforeClose"
  >
    <div class="body">
      <i class="el-icon-success"></i>
      <div class="text">支付成功</div>
      <div class="sub-text">2s后自动跳转分析页</div>

      <div class="title">
        <div class="team left">
          <span class="score">[英甲18]</span><span class="name">弗利特</span>
        </div>
        <div class="line"></div>
        <div class="team right">
          <span class="name">桑德兰</span><span class="score">[英甲22]</span>
        </div>
      </div>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },
  methods: {
    beforeClose() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__body {
    padding: 15px 65px;
  }
}
.body {
  text-align: center;
}
.title {
  height: 80px;
  background: #f6f7f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 0 auto 40px;
  text-align: left;
  .team {
    flex: 1;
    &.left {
      text-align: right;
    }
    .score {
      font-size: 20px;
      color: #999999;
      position: relative;
      top: -2px;
      margin: 5px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
  }
  .line {
    width: 32px;
    height: 1px;
    background: #aeaeae;
    margin: 0 15px;
  }
}
.el-icon-success {
  font-size: 100px;
  color: #16a085;
  margin-top: 50px;
  margin-bottom: 10px;
}
.text {
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
  color: #333333;
  margin-bottom: 20px;
}
.sub-text {
  font-size: 20px;
  line-height: 28px;
  color: #999999;
  margin-bottom: 26px;
}
.el-button {
  width: 260px;
  margin: 0 auto 65px;
  display: block;
}
</style>
